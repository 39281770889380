import React from "react";
import styled from "styled-components";

import { Container } from "../../components/global";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import Header from "../../components/sections/terms-privacy/header";
import Footer from "../../components/sections/footer";

import FAQsList from "../../components/faq/faq-list";
import ContactUs from "../../components/faq/contact-us";
import DownloadAppBanner from "../../components/sections/downloadapp";

const FAQsPage = () => (
  <Layout>
    <SEO title="FAQs" />
    <Navigation background="white" />
    <Header {...pageData.headerData} />
    <Container>
      <FAQsList />
    </Container>
    <ContactUs />
    <DownloadAppBanner className="space-top" />
    <Footer />
  </Layout>
);

export default FAQsPage;

const pageData = {
  headerData: {
    captionText: "Frequently Asked Questions",
    subTitleText: "",
  },
};
